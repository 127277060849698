
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  color: white;
  background-color: gray;
  padding-left: 16px;
}

.layout {
  min-height: calc(100vh - 38px);
}

.products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.layout-header {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  font-weight: 800;
}

.product-tile-wrapper {
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 30%;
  /* float: left;
  width: 25%; */
}

.product-tile {
  /* display: flex;
  flex-direction: column;
  padding: 25px; */
  margin-right: 20px;
  margin-bottom: 20px;
}

.product-tile img {
  max-width: 100%;
  height: auto;
  width: auto\9; /* ie8 */
}

.footer {
  color: white;
  background-color: gray;
  padding-left: 16px;
}